@import "../../custom-bootstrap.scss";

.header {
  background-color: var(--primary-color);
  color: white;
  font-size: 1rem;
  font-weight: 500;
}


.body {
  padding: 1rem 2rem 2rem 2rem;
  background: #1f2427;
  color: white;
}

.footer {
  padding: 1rem 2rem 2rem 2rem;
  background: #1f2427;
  color: white;
}

.firmList{
  margin-right: 10px;
}


.listScroll{
  height: 400px;
  overflow:hidden;
  overflow-y:scroll;
  text-align: center;
}


.emailList{
  color: black;
  margin: 0;
  padding: 0;
}

.invoiceText{
  font-weight: 600;
  text-align: center;
}

.invoice{
  font-weight: 400;
}

.toastedit{
  position: absolute;
  top: -100%;
  background: red !important;
  color: white;
  z-index: 1051;
  left:0;
  right:0;
  margin-left:auto;
  margin-right:auto;
}

@include media-breakpoint-down(md) {
  .toastedit {
    top: -100%;
  }
}
@include media-breakpoint-down(xs) {
  .toastedit {
    top: -100%;
  }
}

.listview {
  background: #1f2427;
  width: 100%;
  cursor:pointer;
}

input[type="file"] {
  display: none;
}
.custom-file-upload {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
}

.attachments{
  float:left;
}

.file-preview {
margin: 0 10px;
}