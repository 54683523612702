.toast{
  position: fixed;
  color: white;
  top:110px;
  z-index: 1100;
}
.success{
  background:green;
}

.error{
  background:red;
}
