.fullWidth {
  width: 100%;
}

.header {
  text-align: center;
  font-size: 1rem;
  padding: 10px;
  cursor: pointer;
  font-weight: 600;
  border: none;
  border-radius: calc(.25rem - 1px) calc(.25rem - 1px);
}
