.root {
  border: 2px dashed #D2D3D4;
  color: #D2D3D4;
  display: table;
  width: 100%;
  height: 100%;
  padding: 10px 0;
  cursor: pointer;
}

.root:hover {
  border: 2px dashed rgb(143, 144, 144);
  color: rgb(143, 144, 144);
}

.inner {
  display: table-cell;
  vertical-align: middle;
}
