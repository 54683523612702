.content {
  background-color: #1f2427;
  color: white;
  border-radius: 0 0 4px 4px;
}

.headerText {
  font-size: 16px;
  font-weight: bold;
}

.text {
  font-size: 14px;
}
