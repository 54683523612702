.header {
  background: #1f2427;
  color: white;
  font-size: 1rem;
  padding: 5px;
}

.editor {
  border: 1px solid #f1f1f1;
  border-radius: 2px;
  padding: 0 10px 0 10px;
}

.saveButton {
  background: #ea2210;
  color: white;
}

.editor-text{
  margin-top: 5px;
}