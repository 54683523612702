/**** LOGIN ****/

:root {
  --primary-color: #BADA55;
}

.btn-primary{
  background-color : var(--primary-color) !important;
}

.form-signin {
  margin: 30px 30px 30px 30px;
}

.element {
  background-color: #1f2427;
  color: white;
  border-top: 2px solid red;
  text-align: center;
  padding: 10px;
}

.vertical-center {
  min-height: 100%; /* Fallback for browsers do NOT support vh unit */
  display: flex;
  align-items: center;
}

.filename {
  white-space: pre;
}

/**** OrderTable ****/

nav {
  width: 100%;
}

hr {
  margin: 0;
}

.exclamation {
  margin-left: 10px;
  cursor: pointer;
}

.badge {
  color: white;
  width: 14px;
  height: 13px;
  text-align: center;
  line-height: 12px;
  border-radius: 50%;
  box-shadow: 0 0 1px #333;
  margin-top: 7px;
  float: left;
  font-size: 11px;
  position: absolute;
  top: 0;
  background: var(--primary-color);
}

/**** DetailPage ****/

h4 {
  background: var(--primary-color);
  color: white;
  text-align: center;
  font-size: 1rem;
  padding: 15px;
  cursor: pointer;
  margin-top: 10px;
}

.black {
  background: #1f2427 !important;
}

.nav-header {
  background: #1f2427 !important;
  border-top: 1px solid var(--primary-color);
}

.black > th {
  background: #1f2427 !important;
}

.save-button {
  background: var(--primary-color);
  color: white;
  float: right;
  min-width: 180px;
  margin: 5px 0 30px 0;
}

.box {
  border: 1px solid #1f2427;
  margin: 40px 0 2px 0;
}

.subtitle {
  margin-bottom: 20px;
}

.text-center {
  text-align: center !important;
  margin-bottom: 20px;
}
.image-upload > input {
  display: none;
}

.image-upload {
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  padding: 20px;
  display: inline-block;
}

.image-upload img {
  width: 50px;
  cursor: pointer;
}

.invoiceText {
  font-weight: 500;
  text-align: center;
}

.image-upload-file > input {
  display: none;
}

.image-upload-file {
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  padding: 20px;
}

.image-upload-file img {
  width: 50px;
  cursor: pointer;
}

.caption {
  font-size: 11px;
}
label > img {
  margin: 0;
}

.icon {
  cursor: pointer;
}

.iconreset {
  cursor: pointer;
  color: green;
}

.faults-header {
  margin-bottom: 0;
}

h6 {
  text-decoration: underline;
}

[data-toggle="collapse"] .fa:before {
  content: "\f139";
}

[data-toggle="collapse"].collapsed .fa:before {
  content: "\f13a";
}
.icon:hover {
  opacity: 0.7;
}

.forgot-password {
  font-size: 14px;
  display: block;
}

