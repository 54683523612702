@import "node_modules/bootstrap/scss/_functions";
@import "node_modules/bootstrap/scss/_variables";

:root {
  --primary-color: #BADA55;
}

.btn-primary{
  background-color : var(--primary-color) !important;
}



$theme-colors: (
  primary: #ea2210,
);

$pagination-active-bg: #6c757d;

$card-cap-bg: #ea2210;

$close-color: #fff;

@import "node_modules/bootstrap/scss/bootstrap";

.custom-control-label {
  width: 100%;
}

.list-group-item-action {
  color: white;
}

.close {
  background: transparent !important;
}

.big {
  max-width: 1000px;
}

.full {
  max-width: 1600px;
}

.nav-tabs .nav-link{
  border:1px solid white;
  color:white;
}

.dropdown-item{
  z-index: 9999;
}

.dropdown-menu {
  left:auto;
  right:0;
}



@media screen and (max-width: 1200px) {
  .react-bootstrap-table{
    overflow-x: scroll;
  }
  .jumbotron{
    padding: 1rem;
    zoom: 0.7;
  }
}

@media screen and (max-width: 1100px) {
  .modal-content{
    height:100%
  }
  
  .modal-dialog{
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
}

tr.highlight{
  background-color: #00000013 !important;
}
.table th, .table td {
  padding: 0.5rem
}


.table-hover tbody tr:hover {
  background-color: #00000013 !important;
}

.table-bordered{
  background: #fff;
}

.parentTable > div > div > div > div > table > tbody > tr > td{
  white-space: normal;
  word-break: break-word;
}