@import "../../custom-bootstrap.scss";


.dataTable table {
  table-layout: auto;
}

.dataTable th {
  border: none;
}

.dataTable tbody tr {
  background-color: lightgray;
}

.header {
  background-color: var(--primary-color);
  color: white;
  border: none;
}

:global(.table-bordered td) {
  border: 2px solid #dee2e6;
 white-space: pre;
  text-align: left !important;
}

@include media-breakpoint-down(md) {
  .dataTable table {
    border: none;
  }

  .dataTable thead {
    position: absolute;
    left: -9999px;
  }

  .dataTable tr {
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: 25px;
  }

  .dataTable tr:last-child {
    margin-bottom: 0px;
  }

  .dataTable td {
    display: block;
    text-align: right !important;
    min-height: 52px;
  }

  .dataTable td::before {
    float: left;
    font-weight: bold;
    content: attr(data-header);
  }

  .dataTable td:last-child {
    border-bottom: 0;
  }

  h1{
    font-size: 28px;
  }

}
