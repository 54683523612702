@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins/_breakpoints";

.titleRow {
  height: 35px;
}

@include media-breakpoint-up(lg) {
  .titleRow {
    height: 50px;
  }
}
